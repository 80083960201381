a {
	color: var(--blue-500);

	&:hover {
		color: var(--blue-500);
    text-decoration: none;
	}
}
.checkout-payment__svg-icon {
  width: 100px;
  height: 33px;

  &#payment-meeza {
    height: 24px;
  }
  &#payment-vodafone-cash {
    height: 36px;
  }
}
