.user-header-menu-mini-card {
  --user-header-menu-mini-card-background: #252a2a;
  --card-card-subtitle-color: #707070;
  --card-card-discounted-price-color: #a1a1a1;
  --user-header-menu-mini-card-title-color: #eeeeee;
  --user-header-menu-mini-card-original-price: #0a8db1;
  --user-header-menu-mini-card-coupon-color: #2aae38;
  --user-header-menu-mini-card-course-card-image-container-backgroung: #7f94fe;
  --user-header-menu-mini-card-course-card-btn-icon-color: #d4d4d4;
  --user-header-menu-mini-hr-color: #3b4242;
  --user-header-menu-mini-bundle-courses-color: var(--blue-500);

  .light & {
    --user-header-menu-mini-card-background: #fff;
    --user-header-menu-mini-card-title-color: #000;
    --user-header-menu-mini-card-course-card-btn-icon-color: #000;
    --user-header-menu-mini-hr-color: #d9d9d9;
  }

  width: 100%;
  background-color: var(--user-header-menu-mini-card-background);
  border-radius: 10px;

  color: var(--user-header-menu-mini-card-title-color);

  &__course-card-container {
    max-height: 360px;
  }

  &__course-card,  &__card-content-container {
    padding: 0;
    margin: 0 0 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    background-color: transparent;
  }

  &__image-container {
    max-width: 150px;
    max-height: 50%;
    height: 80px;
    border-radius: 15px;


    img {
      border-radius: 10px;
      height: inherit;
      width: 100%;
      align-self: stretch;
      object-fit: cover;
    }

    @media (max-width: 550px) {
      height: 90px;
    }
  }

  &__info-container {
    width: calc(100% - 165px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    @media (max-width: 550px) {
      width: calc(100% - 155px);
    }
  }

  &__top-info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  }


  &__title-container {
    font-size: 18px;
    color: var(--user-header-menu-mini-card-title-color);
    max-width: calc(75% - 50px);
    margin: 0 25px;

    p {
      color: var(--card-card-subtitle-color);
      font-size: 12px;
      margin-bottom: 0;
    }

    h6 {
      font-size: 14px;
    }

    &__bundle-courses {
      color: var(--user-header-menu-mini-bundle-courses-color) !important;
      font-size: 14px;

      svg {
        width: 19px;
        height: 19px;
        fill: var(--user-header-menu-mini-bundle-courses-color);
        vertical-align: top;

        [dir="ltr"] & {
          margin-right: 5px;
        }

        [dir="rtl"] & {
          margin-left: 5px;
        }
      }
    }
  }

  &__price-container, &__price {
    margin-inline-start: auto;
    height: 100%;
  }

  &__price {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }

  &__discounted-price {
    margin-inline-start: auto;
    text-decoration: line-through;
    color: var(--card-card-subtitle-color);
    font-size: 12px;
  }

  &__original-price {
    color: var(--user-header-menu-mini-card-original-price);
    font-weight: bold;
    width: max-content;
    font-size: 14px;

  }


  &__actions {
    .btn-icon {
      padding: 0px;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-inline-start: auto;

    svg {
      width: 18px;
      height: 18px;
      fill: var(--user-header-menu-mini-card-course-card-btn-icon-color);
    }

  }


  hr {
    background-color: var(--user-header-menu-mini-hr-color);
    margin-top: 0 !important;
  }

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--user-header-menu-mini-hr-color);
  }

  &__footer {
    border-top: 1px solid var(--user-header-menu-mini-hr-color);
  }

  &__footer-details {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
  }

  &__footer-title {
    align-self: center;

    h5 {
      margin-bottom: 0;
      font-weight: bold;
      color: var(--user-header-menu-mini-card-title-color);
    }
  }

  .btn-danger {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

}
