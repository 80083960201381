::-webkit-scrollbar {
	width: 11px;
}

*::-webkit-scrollbar-track {
  background-color: var(--metal-400);
}

*.light {
  &::-webkit-scrollbar-track {
    background-color: var(--white-500);
  }
}

*::-webkit-scrollbar-thumb {
  background-color: var(--white-500);
}

*.light {
  &::-webkit-scrollbar-thumb {
    background-color: var(--metal-400);
  }
}
