.section {
  padding-top: 150px;
  padding-bottom: 150px;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  &__header {
    align-items: center;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}

.container {

  @media (min-width: 1200px) {
    max-width: 1380px;
  }

  @media (max-width: 1400px) {
    width: 95%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 1085px;
  }

  @media (max-width: 320px) {
    padding-right: 5px;
    padding-left: 5px;
    max-width: 100% !important;
  }

  @media (min-width: 321px) and (max-width: 991px) {
    max-width: 95% !important;
  }
}

.custom-container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}
