.auth-step-control, .dialog-close{
  --modal-back-button-color: #d4d4d4;
  --modal-back-button-background: var(--metal-400);
  --modal-close-button-background: var(--dark-500);
}

.light {
  .auth-step-control,
    //.dialog-close
  {
    --modal-back-button-color: var(--dark-500);
    --modal-back-button-background: #fff;
    --modal-close-button-background: var(--white);
  }
}

.auth-step-control,
  //.dialog-close
{
  display: flex;
  justify-content: flex-start;
  align-items: center;

  button {
    width: 42px;
    height: 42px;
    padding: 0;
    border: none;
    border-radius: 50%;
    background-color: var(--modal-back-button-background);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 35px 0 35px;
    opacity: 1;
    &:hover, &:focus{
      outline: none;
    }
    svg{
      width: 18px;
      height: 12px;
      fill: var(--modal-back-button-color);
    }

    @media (max-width: 620px) {
      margin: 15px 0 0 0;
    }
  }
}

.auth-step-control{
  button{
    svg{
      margin: 0 7px;
      width: 18px;
      height: 12px;
    }
  }
}

.dialog-close {
  //button{
  //  background-color: var(--modal-close-button-background);
  //  svg{
  //    width: 18px;
  //    height: 18px;
  //  }
  //}
}

[dir="rtl"] {
  .auth-step-control button > svg {
    transform: rotateY(0deg);
  }
}

[dir="ltr"] {
  .auth-step-control button > svg {
    transform: rotateY(180deg);
  }
}

// Generalize the styles of the dialogs
// instead of set the styles on each dialog
.cdk-overlay-container {
  // this is to be on top of zendesk support fab which has z-index 999998
  z-index: 999;
}

.alm-default-dialog-panel {
  .mat-mdc-dialog-container {
    --mdc-dialog-container-color: var(--main-background-color);
    --mdc-dialog-container-elevation: none;
    background-color: var(--main-background-color);
    color: var(--main-text-color);
    border-radius: 10px;
    padding: 40px 20px;
  }
}

.alm-blur-backdrop {
  backdrop-filter: blur(20px);
}

@media(max-width: 768px) {
  .alm-default-dialog-panel {
    max-width: 95vw!important;
    .mat-mdc-dialog-container {
      padding: 24px 12px;
    }
  }
}

.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-tracking: normal;
}
