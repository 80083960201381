.mdc-tab {
   .mdc-tab__ripple::before {
      background-color: unset !important;
   }
   .mdc-tab__text-label {
      letter-spacing: normal;
   }
}
.mat-mdc-menu-content {
   letter-spacing: normal !important;
}