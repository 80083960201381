.toast-container {
  pointer-events: auto;
}

.alm-toaster {

  --alm-toaster-background: #3f4848;
  --alm-toaster-text: #eeeeee;
  --alm-toaster-text-hover: #999999;

  .light & {
    --alm-toaster-background: #fbfafa;
    --alm-toaster-text: #333333;
    --alm-toaster-text-hover: #aaaaaa;
    box-shadow: 2px 6px 10px 3px rgb(0, 0, 0, 0.5)
  }

  width: 400px;
  margin-top: 15px;
  min-height: 85px;
  background: var(--alm-toaster-background);
  border-radius: 10px;
  color: var(--alm-toaster-text);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding: 10px;

  @media (max-width: 768px) {
    width: 250px;
  }

  .toast-close-button {
    position: absolute;
    top: 0;
    font-size: 25px;
    color: var(--alm-toaster-text);
    box-shadow: none;
    text-shadow: none;

    [dir="ltr"] & {
      right: 10px;
      left: unset;
    }

    [dir="rtl"] & {
      left: 10px;
      right: unset;
    }

    &:hover {
      color: #999999;
    }

    &:focus {
      outline: none;
    }
  }

  .notification-toaster-title {
    font-size: 17px;
    font-weight: bold;
    margin: 20px 0 15px 0;
    word-break: break-all;

    [dir="ltr"] & {
      text-align: left;
    }

    [dir="rtl"] & {
      text-align: right;
    }
  }

  .toast-message {
    font-size: 15px;
    word-break: break-all;
    margin-bottom: 10px;

    [dir="ltr"] & {
      text-align: left;
    }

    [dir="rtl"] & {
      text-align: right;
    }
  }
}
