.payment-phone-input {
  width: 100%;

  .form-control {
    width: 100%;
    height: 43px !important;
    border: solid 1px #d2d2d2;
    background-color: transparent !important;
    font-size: 14px;
    color: #000;
    border-left: none !important;

    &::placeholder {
      color: #bcbcbc;
      font-size: 14px;
    }
  }

  .phone-input__prefix {
    height: 43px !important;
    border: solid 1px #d2d2d2;
    background-color: transparent !important;
    font-size: 14px;
    color: #000;
    width: 80px !important;
    border-right: none !important;

    &-separator {
      border-color: #000 !important;
    }
  }

  .mat-mdc-select {
    background-color: transparent !important;
  }

  .phone-input__prefix-country {
    color: #000;
  }
}

.payment-form-group {
  width: 100%;
  height: 43px;

  input, mat-select {
    background-color: transparent !important;
    margin: 4px 0 0 !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-input-element:disabled,.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: rgba(0, 0, 0, 0.38);
  }
  &-with-button {
    input {
      margin: 0 !important;
    }
  }

  &-with-text {
    //.payment-form-control {
    //  max-width: 233px;
    //}
  }

  .prefix-icon {
    background-color: transparent !important;
    display: none;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    height: 43px;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  &.mat-mdc-form-field .mat-form-field-infix {
    padding: 0 !important;
  }

  &.mat-mdc-form-field {
    .mat-mdc-select {
      background-color: transparent !important;
      /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-trigger {
        height: 43px !important;
        padding: 16px;

        &::placeholder {
          color: #bcbcbc;
          font-size: 14px;
        }
      }
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-invalid .mat-form-field-invalid .mat-form-field-outline-thick {
    height: 43px !important;
    top: 0 !important;
  }
}

.payment-form-control {
  width: 100%;
  height: 43px !important;
  border-radius: 5px;
  border: solid 1px #d2d2d2 !important;
  background-color: transparent !important;
  font-size: 14px;
  color: #000;

  &:focus {
    background-color: transparent !important;
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: #bcbcbc;
    font-size: 14px;
  }
}

.payment-form-label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px !important;
}

[dir='ltr'] {
  .payment-form-group-with-button {
    .payment-form-control {
      border-radius: 5px 0 0 5px;
      border-right: none;
    }

    .btn-alm-filled {
      border-radius: 0 5px 5px 0;

      &:hover {
        border-radius: 0 5px 5px 0;
      }
    }
  }
}

[dir='rtl'] {
  .payment-form-group-with-button {
    .payment-form-control {
      border-radius: 0 5px 5px 0;
      border-left: none;
    }

    .btn-alm-filled {
      border-radius: 5px 0 0 5px;

      &:hover {
        border-radius: 5px 0 0 5px;
      }
    }
  }
}


